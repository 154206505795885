/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import StoreContext from '../../store/context';

import { EditContrato } from '../../services';

const useStyles = makeStyles((theme) => createStyles({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModalEditContrato({
  users,
  handleClickOpen,
  open,
  handleClose,
  editMode,
  clienteID,
  refreshFunction,
  contrato_id,
}) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [values, setValues] = useState('');

  const [disabled, setDisabled] = useState(false);
  const handleChange = (event) => {
    setValues(event.target.value);
  };
  // useEffect(() => {
  //   console.log('zeahha', contrato_id);
  //   // if (editMode) {
  //   //   setValues({ ...values, contrato_id, cliente_id: parseInt(clienteID) });
  //   // } else {
  //     setValues({ ...values, cliente_id: parseInt(clienteID) });
  //   }
  // }, []);

  async function handleEditContrato() {
    try {
      setValues('');
      setDisabled(true);
      await EditContrato(token, contrato_id, values);
      await refreshFunction();
      handleClose(false);
      console.log(values);
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  }

  return (
    <div>
      <Dialog
        style={{ maxWidth: '50wh' }}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >
        {editMode ? (
          <DialogTitle id="form-dialog-title">Editar Nome Contrato</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">Editar Contrato</DialogTitle>
        )}
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="descricao"
            label="Contrato"
            type="text"
            name="descricao"
            fullWidth
            onChange={handleChange}
            value={values}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button onClick={handleEditContrato} color="primary">
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
