/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import api from '../../services/api';

import { uploadPDF } from '../../services';
import StoreContext from '../../store/context';
import TheUploader from '../TheUploader';
import { setHeaders } from '../../utils/headers';

const useStyles = makeStyles((theme) => (
  createStyles({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })));

export default function FormDialog({
  users,
  handleClickOpen,
  open,
  handleClose,
  editMode,
  contrato_id,
  currentUser,
  refreshFunction,
}) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [values, setValues] = useState({
    descricao: '',
    observacao: '',
    user_ower: 0,
    contrato_id: 0,
    montador: '',
  });
  const [pdf, setPdf] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const handleChange = (event) => {
    console.log('Selected', event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (editMode) {
      setValues(currentUser);
    } else {
      setValues({ ...values, contrato_id: parseInt(contrato_id) });
    }
  }, []);

  function onFileDraged(file) {
    console.log('file', file);
    setPdf(file[0]);
  }
  async function handleSavePDF(projeto_id, url, name) {
    try {
      const dataParams = {
        projeto_id,
        url,
        name,
        type: 'application/pdf',
        subtype: 'pdf',
        file: ' ',
      };
      const { data } = await api.post(
        'uploadpdf',
        dataParams,
        setHeaders(token),
      );
      console.log('*** SUCCESS ****', data);
      handleClose(false);

      refreshFunction();
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  }
  async function handleOnUpload(id) {
    try {
      const formData = new FormData();
      formData.append('file', pdf);
      const { data } = await uploadPDF(id, token, formData);
      return data;
      // const pdfUp = await api.post(`uploadpdf/${id}/projeto`, formData, setHeaders(token, true));
    } catch (e) {
      return e;
    }
  }

  async function handleAddProject(data) {
    try {
      setDisabled(true);
      const {
        descricao,
        observacao,
        contrato_id: currentContract,
        user_ower: professionalID,
      } = data;
      const body = {
        descricao,
        observacao,
        contrato_id: currentContract,
        user_ower: professionalID,
      };
      console.log('body projetos', body);

      const projeto = await api.post('projeto', body, setHeaders(token));
      await handleOnUpload(projeto.data.id);
      handleClose();
      setDisabled(false);
      console.log(values);
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  }

  return (
    <div>
      <Dialog
        style={{ maxWidth: '50wh' }}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >
        {editMode ? (
          <DialogTitle id="form-dialog-title">Editar projeto</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">Novo projeto</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>Digite as Informações</DialogContentText>
          <TextField
            style={{ marginTop: 10 }}
            autoFocus
            margin="dense"
            id="descricao"
            label="Descrição"
            type="text"
            name="descricao"
            fullWidth
            onChange={handleChange}
            value={values.descricao}
          />
          <TextField
            style={{ marginTop: 10 }}
            autoFocus
            margin="dense"
            id="observacao"
            label="Observação"
            type="text"
            name="observacao"
            fullWidth
            onChange={handleChange}
            value={values.observacao}
          />
          <FormControl
            style={{ marginTop: 10, maxWidth: '50wh' }}
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-label">Montador</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="montador"
              onChange={handleChange}
              value={values.user_ower}
              fullWidth
              name="user_ower"
            >
              {users.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TheUploader onFileDraged={onFileDraged} />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            onClick={() => handleClose(false)}
            color="primary"
          >
            Cancelar
          </Button>
          {editMode ? (
            <Button disabled={disabled} onClick={handleClose} color="primary">
              Alterar
            </Button>
          ) : (
            <Button
              disabled={disabled}
              onClick={() => handleAddProject(values)}
              color="primary"
            >
              Cadastrar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
