/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import cep from 'cep-promise';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import InputCnpj from '../InputCnpj';
import InputTelefone from '../inputTelefone';
import InputCep from '../InputCep';
import api from '../../services/api';
import StoreContext from '../../store/context';

const useStyles = makeStyles(() => ({
  root: {},
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const FormLoja = ({
  className,
  editMode,
  loja,
  title,
  subheader,
  refreshFunction,
  ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    franquia: ' ',
    nome_fantasia: '',
    razao_social: '',
    cnpj: '',
    inscricao_estadual: 'isento',
    email: '',
    cep: '',
    logadouro: '',
    numero: '',
    complemento: ' ',
    bairro: '',
    cidade: ' ',
    estado: '',
    telefone: '',
    proprietario: '',
  });
  const [cepInsert, setCepInsert] = useState('');
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const { token } = useContext(StoreContext);
  const navigate = useNavigate();
  useState(() => {
    if (editMode) {
      setValues(loja);
      setCepInsert(loja.cep);
      return;
    }
    setEdit(true);
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  async function handleAdd(value) {
    try {
      setLoading(true);
      const { cnpj, telefone } = value;
      // eslint-disable-next-line no-param-reassign
      value.cnpj = cnpj.replace(/\D+/g, '');
      value.telefone = telefone.replace(/\D+/g, '');
      console.log(value);
      // eslint-disable-next-line no-unused-vars
      const data = await api.post('loja', value, {
        headers: { Authorization: `bearer ${token}` },
      });

      setLoading(false);
      navigate('/app/', { replace: true });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  async function handleEdit(value) {
    try {
      setLoading(true);
      const { cnpj, telefone } = value;
      // eslint-disable-next-line no-param-reassign
      value.cnpj = cnpj.replace(/\D+/g, '');
      value.telefone = telefone.replace(/\D+/g, '');
      const d = await api.put(`loja/${value.id}`, value, {
        headers: { Authorization: `bearer ${token}` },
      });
      console.log('loja edit', d);
      setLoading(false);
      refreshFunction();
    } catch (e) {
      console.log(e);
    }
  }

  async function getCep(number) {
    try {
      const data = await cep(number);
      const { state, city, neighborhood, street, cep: newCep } = data;
      setValues({
        ...values,
        estado: state,
        cidade: city,
        bairro: neighborhood,
        logadouro: street,
        cep: newCep,
      });
    } catch (e) {
      console.log('erro ao consultar o cep');
    }
  }
  async function handleCep(event) {
    try {
      const n = event.target.value.replace(/\D+/g, '');
      console.log('value', n.length);
      if (n.length === 8) {
        setCepInsert(n);
        await getCep(n);
      } else {
        setCepInsert(n);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleUpdateStatus(id, status) {
    try {
      setLoading(true);
      const data = await api.post(
        'updatestatus',
        { loja_id: id, status },
        { headers: { Authorization: `bearer ${token}` } },
      );
      setLoading(false);
      refreshFunction();
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader={subheader} title={title} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Por favor, digite o nome da franquia"
                label="Nome da Loja"
                name="franquia"
                onChange={handleChange}
                required
                value={values.franquia}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nome Fantasia"
                name="nome_fantasia"
                onChange={handleChange}
                required
                disabled={!edit}
                value={values.nome_fantasia}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Razão Social"
                name="razao_social"
                onChange={handleChange}
                required
                value={values.razao_social}
                disabled={!edit}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="CNPJ"
                name="cnpj"
                onChange={handleChange}
                required
                value={values.cnpj}
                disabled={!edit}
                InputProps={{
                  inputComponent: InputCnpj,
                }}
                variant="outlined"
              />
              {/* <FormControl>
                <InputLabel htmlFor="formatted-text-mask-input">CNPJ</InputLabel>
                <Input
                  value={values.cnpj}
                  onChange={handleChange}
                  name="cnpj"
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                />
              </FormControl> */}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Proprietário ou Responsável"
                label="Proprietário ou Responsável"
                name="proprietario"
                onChange={handleChange}
                required
                value={values.proprietario}
                disabled={!edit}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                disabled={!edit}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Telefone"
                name="telefone"
                onChange={handleChange}
                value={values.telefone}
                disabled={!edit}
                InputProps={{
                  inputComponent: InputTelefone,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="CEP"
                name="cep"
                onChange={handleCep}
                value={cepInsert}
                disabled={!edit}
                variant="outlined"
                InputProps={{
                  inputComponent: InputCep,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Logadouro"
                name="logadouro"
                value={values.logadouro}
                variant="outlined"
                disabled
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numero"
                name="numero"
                value={values.numero}
                variant="outlined"
                disabled
              />

            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Bairro"
                name="bairro"
                value={values.bairro}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Cidade"
                name="cidade"
                value={values.cidade}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Estado"
                name="estado"
                value={values.estado}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Numero"
                name="numero"
                value={values.numero}
                disabled={!edit}
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Complemento"
                name="complemento"
                value={values.complemento}
                disabled={!edit}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select State"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {editMode ? (
            <>
              {!edit ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setEdit(true)}
                  disabled={loading}
                >
                  Editar
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleEdit(values)}
                  disabled={loading}
                >
                  Confirmar
                </Button>
              )}
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAdd(values)}
              disabled={loading}
            >
              Salvar
            </Button>
          )}
          {loja.ativado ? (
            <Button
              style={{ marginLeft: 10 }}
              color="secondary"
              variant="contained"
              onClick={() => handleUpdateStatus(loja.id, false)}
            >
              Desativar
            </Button>
          ) : (
            <Button
              style={{
                marginLeft: 10,
                backgroundColor: 'green',
                color: 'white',
              }}
              color="success"
              variant="contained"
              onClick={() => handleUpdateStatus(loja.id, true)}
            >
              Ativar
            </Button>
          )}
        </Box>
      </Card>
    </form>
  );
};

FormLoja.propTypes = {
  className: PropTypes.string,
  editMode: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  loja: PropTypes.object,
};

export default FormLoja;
