/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import cep from 'cep-promise';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,

} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import InputTelefone from '../inputTelefone';
import InputCep from '../InputCep';
import api from '../../services/api';
import StoreContext from '../../store/context';

const useStyles = makeStyles(() => ({
  root: {},
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const FormCliente = ({
  className, editMode, cliente, title, subheader, refreshFunction, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    nome: '',
    email: '',
    cep: '',
    logadouro: '',
    numero: '',
    complemento: ' ',
    bairro: '',
    cidade: ' ',
    estado: '',
    telefone: '',
  });
  const [cepInsert, setCepInsert] = useState('');
  const [loading, setLoading] = useState(false);
  const { token } = useContext(StoreContext);
  const navigate = useNavigate();
  useState(() => {
    if (editMode) {
      setValues(cliente);
      setCepInsert(cliente.cep);
      console.log('edit mode', cliente);
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  async function handleAdd(value) {
    try {
      setLoading(true);
      const { telefone } = value;
      // eslint-disable-next-line no-param-reassign

      value.telefone = telefone.replace(/\D+/g, '');
      console.log(value);
      // eslint-disable-next-line no-unused-vars
      const data = await api.post('cliente', value, { headers: { Authorization: `bearer ${token}` } });

      setLoading(false);
      navigate('/app/', { replace: true });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  async function handleEdit(value) {
    try {
      setLoading(true);
      const { telefone } = value;
      // eslint-disable-next-line no-param-reassign
      value.telefone = telefone.replace(/\D+/g, '');
      const d = await api.put(`cliente/${value.id}`, value, { headers: { Authorization: `bearer ${token}` } });
      console.log('cliente edit', d);
      setLoading(false);
      refreshFunction();
    } catch (e) {
      console.log(e);
    }
  }

  async function getCep(number) {
    try {
      const data = await cep(number);
      const {
        state, city, neighborhood, street, cep: newCep,
      } = data;
      setValues({
        ...values,
        estado: state,
        cidade: city,
        bairro: neighborhood,
        logadouro: street,
        cep: newCep,
      });
    } catch (e) {
      console.log('erro ao consultar o cep');
    }
  }
  async function handleCep(event) {
    try {
      const n = event.target.value.replace(/\D+/g, '');
      console.log('value', n.length);
      if (n.length === 8) {
        setCepInsert(n);
        await getCep(n);
      } else {
        setCepInsert(n);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader={subheader}
          title={title}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Por favor, digite o nome do cliente"
                label="Nome do cliente"
                name="nome"
                onChange={handleChange}
                required
                value={values.nome}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefone"
                name="telefone"
                onChange={handleChange}
                value={values.telefone}
                InputProps={{
                  inputComponent: InputTelefone,
                }}
                variant="outlined"
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CEP"
                name="cep"
                onChange={handleCep}
                value={cepInsert}
                variant="outlined"
                InputProps={{
                  inputComponent: InputCep,
                }}

              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Logadouro"
                name="logadouro"
                value={values.logadouro}
                variant="outlined"
                disabled
              />

            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bairro"
                name="bairro"
                value={values.bairro}
                variant="outlined"
                disabled
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cidade"
                name="cidade"
                value={values.cidade}
                variant="outlined"
                disabled
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Estado"
                name="estado"
                value={values.estado}
                variant="outlined"
                disabled
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numero"
                name="numero"
                value={values.numero}
                variant="outlined"
                onChange={handleChange}
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Complemento"
                name="complemento"
                value={values.complemento}
                onChange={handleChange}
                variant="outlined"
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {editMode
            ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleEdit(values)}
                disabled={loading}
              >
                Editar
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleAdd(values)}
                disabled={loading}
              >
                Salvar
              </Button>
            ) }
        </Box>
      </Card>
    </form>
  );
};

FormCliente.propTypes = {
  className: PropTypes.string,
  editMode: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  cliente: PropTypes.object,
};

export default FormCliente;
