/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileCopyRounded, Delete as DeleteIcon, Pageview as ViewIcon } from '@material-ui/icons';
import {
  Paper,
  Grid,
  makeStyles,
  TextField,
  Button,
  Typography,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Skeleton from '@material-ui/lab/Skeleton';

import { useParams, useNavigate } from 'react-router-dom';

import moment from 'moment';
import StoreContext from '../../../store/context';
import {
  EditProjeto,
  getProject,
  DeleteProjeto,
  uploadPDF,
  deletePdf,
} from '../../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cursor: {
    cursor: 'pointer',
  },
  primaryText: {
    fontSize: 10,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function NewProject() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [btnModalDelete, setBtnModalDelete] = useState(false);
  const [values, setValues] = useState({ filePdf: [] });
  const [assetId, setAssetId] = useState('');
  const [fileId, setFileId] = useState(0);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [pdf, setPdf] = useState(null);
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    accept: 'application/pdf',
    onDrop: (file) => {
      setPdf(file);
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function getCurrentProject() {
    try {
      setLoading(true);
      const p = await getProject(token, id);
      setValues(p);
      setLoading(false);
      console.log(p);
    } catch (e) {
      console.log('erro ao acessar projeto');
    }
  }
  async function onUpload(singlePdf) {
    try {
      const formData = new FormData();
      formData.append('file', singlePdf);
      const { data } = await uploadPDF(id, token, formData);

      console.log(data);
      return data;
      // const pdfUp = await api.post(`uploadpdf/${id}/projeto`, formData, setHeaders(token, true));
    } catch (e) {
      return e;
    }
  }

  async function handleSaveUpload() {
    try {
      setLoadingModal(true);
      for (const p of pdf) {
        await onUpload(p);
      }
      setLoadingModal(false);
      handleClose();
      await getCurrentProject();
      return;
    } catch (e) {
      return e;
    }
  }

  useEffect(() => {
    (async () => {
      await getCurrentProject();
    })();
  }, []);

  const files = acceptedFiles.map((file) => (
    <ListItem
      style={{
        maxWidth: '100%',
        backgroundColor: '#eee',
        margin: 10,
      }}
      button
      key={file.path}
    >
      <ListItemIcon>
        <PictureAsPdfIcon />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.primaryText }}
        inset
        primary={file.name}
      />
    </ListItem>
  ));

  async function handleEditProjeto() {
    try {
      setLoading(true);
      const project = await EditProjeto(token, id, values);
      setLoading(false);
      navigate(-1);
      return project;
    } catch (e) {
      console.log(e);
      setLoading(false);
      return e;
    }
  }
  async function handleDeleteProjeto() {
    try {
      setLoading(true);
      const project = await DeleteProjeto(token, id);
      setLoading(false);
      navigate(-1);
      return project;
    } catch (e) {
      console.log(e);
      setLoading(false);
      return e;
    }
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  function handleClickConfirmDelete(idFileAsset, idFileProject) {
    setFileId(idFileProject);
    setAssetId(idFileAsset);
    setOpenModalDelete(true);
  }

  async function handleDeletePdf() {
    setBtnModalDelete(true);
    await deletePdf(fileId, assetId, token);
    console.log(`AssetID ${assetId} and FileID ${fileId}`);
    setOpenModalDelete(false);
    setBtnModalDelete(false);
    setFileId(0);
    setAssetId('');
    await getCurrentProject();
  }

  function handleCancelDeletePdf() {
    setFileId(0);
    setAssetId('');
    setOpenModalDelete(false);
  }
  return (
    <Paper className={classes.root} elevation={3}>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        style={{ paddingLeft: 30, paddingRight: 30 }}
        spacing={4}
      >
        {loading ? (
          <>
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
          </>
        ) : (
          <>
            <Grid xs={6} sm={6} lg={6} md={6} xl={6} item>
              <TextField
                name="descricao"
                label="Descrição"
                placeholder="Descrição"
                fullWidth
                value={values.descricao}
                edi
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid xs={6} sm={6} lg={6} md={6} xl={6} item>
              <TextField
                name="observacao"
                label="Observação"
                placeholder="Observação"
                fullWidth
                onChange={handleChange}
                margin="normal"
                value={values.observacao}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid xs={6} sm={6} lg={12} md={12} xl={12} item>
              <Typography align="center" variant="h5">
                Arquivos do projeto
                <IconButton
                  onClick={() => handleClickOpen()}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon />
                </IconButton>
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Criado em:</TableCell>
                      <TableCell align="center">Ações</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.filePdf.map((row) => (
                      <TableRow key={row.asset_id}>
                        <TableCell component="th" align="center" scope="row">
                          {moment(row.created_at).format('DD/MM/YYYY')}

                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => window.open(row.secure_url, '_blank')} color="primary">
                            <ViewIcon />
                          </IconButton>
                          <IconButton onClick={() => handleClickConfirmDelete(row.asset_id, row.id)} color="secondary">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
            <Grid xs={6} sm={6} lg={1} md={6} xl={6} item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleEditProjeto()}
                disabled={loading}
                style={{ paddingRight: 10 }}
              >
                Editar
              </Button>
            </Grid>
            <Grid xs={6} sm={6} lg={1} md={6} xl={6} item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleDeleteProjeto()}
                disabled={loading}
              >
                Apagar
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              disabled={loadingModal}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Fazer Upload
            </Typography>
            <Button disabled={loadingModal} autoFocus color="inherit" onClick={handleSaveUpload}>
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        {loadingModal ? (
          <>
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
          </>
        ) : (
          <>

            <Grid
              container
              justify="flex-end"
              alignItems="center"
              style={{ paddingLeft: 30, paddingRight: 30, marginTop: 10 }}
              spacing={4}
            >
              <Paper
                style={{
                  minHeight: '30vh',
                  width: '100%',
                  backgroundColor: '#eee',
                }}
                elevation={1}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input {...getInputProps()} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '30vh',
                    height: '100%',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderStyle: 'dotted',
                    borderColor: '#ccc',
                  }}
                >
                  <Typography
                    align="center"
                    variant="h6"
                    className={classes.cursor}
                    component="h2"
                  >
                    Arraste o arquivo aqui ou clique.
                  </Typography>
                  <FileCopyRounded />
                </div>
              </Paper>
              <Paper
                style={{
                  display: 'flex',
                  minHeight: '50vh',
                  marginTop: 10,
                  width: '100%',
                  justifyContent: 'center',
                }}
                elevation={1}
              >
                <List component="nav" className={classes.root}>
                  {files}
                </List>
              </Paper>
            </Grid>
          </>
        ) }
      </Dialog>
      <Dialog
        open={openModalDelete}
        onClose={() => handleCancelDeletePdf()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deletar PDF</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voce quer apagar o arquivos da base de dados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelDeletePdf()} color="primary">
            Disagree
          </Button>
          <Button disabled={btnModalDelete} onClick={() => handleDeletePdf()} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
