import React from 'react';
import Context from './context';
import useStorage from '../hooks/useStorage';

// eslint-disable-next-line react/prop-types
const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [user, setUser] = useStorage('user');

  return (
    <Context.Provider value={{
      token, setToken, user, setUser,
    }}
    >

      {children}
    </Context.Provider>
  );
};

export default StoreProvider;
