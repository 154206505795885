import React from 'react';

import ClientesTable from '../../components/ClientesTable';

export default function Clientes() {
  return (
    <div>
      <ClientesTable />
    </div>
  );
}
