import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';

import ResultsClientes from './ResultsClientes';
import Toolbar from './Toolbar';
import Page from '../Page';

import api from '../../services/api';
import { setHeaders } from '../../utils/headers';
import StoreContext from '../../store/context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
const ClientesTable = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const { token } = useContext(StoreContext);
  useEffect(() => {
    async function getLojas() {
      try {
        const l = await api.get('cliente', setHeaders(token));
        setCustomers(l.data);
      } catch (e) {
        console.log('Error', e);
      }
    }
    getLojas();
  }, []);
  return (
    <Page
      className={classes.root}
      title="Lojas"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <ResultsClientes customers={customers} />
        </Box>
      </Container>
    </Page>

  );
};

export default ClientesTable;
