import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,

  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ContactsIcon from '@material-ui/icons/Contacts';
import GroupIcon from '@material-ui/icons/Group';
// import BusinessIcon from '@material-ui/icons/Business';
import {
  // AlertCircle as AlertCircleIcon,

  // Lock as LockIcon,
  // Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  // Users as UsersIcon,
} from 'react-feather';
import NavItem from './NavItem';
import StoreContext from '../../../store/context';

const items = [
  {
    href: '/app/',
    icon: ShoppingBagIcon,
    title: 'Lojas',
  },
  // {
  //   href: '/app/usuarios',
  //   icon: UsersIcon,
  //   title: 'Usuários',
  // },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products',
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account',
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings',
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login',
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register',
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error',

];
const admin = [
  {
    href: '/app/',
    icon: ContactsIcon,
    title: 'Meus Clientes',
  },
  {
    href: '/app/usuarios',
    icon: GroupIcon,
    title: 'Meus usuários',
  },
  // {
  //   href: '/app/products',
  //   icon: BusinessIcon,
  //   title: 'Minha Loja',
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account',
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings',
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login',
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register',
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error',
  // },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(StoreContext);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  function renderMenuUser(currentUser) {
    if (currentUser.cargo === 'master') {
      return (items.map((item) => (
        <NavItem
          href={item.href}
          key={item.title}
          title={item.title}
          icon={item.icon}
        />
      )));
    }
    if (currentUser.cargo === 'admin') {
      return (admin.map((item) => (
        <NavItem
          href={item.href}
          key={item.title}
          title={item.title}
          icon={item.icon}
        />
      )));
    }
    return null;
  }
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src="/static/images/avatars/avatar_6.png"
          to="/"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.email}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>

          {renderMenuUser(user)}
        </List>
      </Box>
      <Box flexGrow={1} />
      {/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
