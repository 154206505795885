/* eslint-disable max-len */
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, makeStyles, LinearProgress, Grid,
} from '@material-ui/core';

import Page from '../../components/Page';
import StoreContext from '../../store/context';
import ToolbarContract from '../../components/ToolbarContract';
import AccordionStatus from '../../components/AccordionStatus';
import AccordionStatusRivisao from '../../components/AccordionStatusRivisao';
import TableContrato from '../../components/TableContrato';
import ModalProjeto from '../../components/ModalProjeto';
import ModalEditContrato from '../../components/ModalEditContrato';
import { getContractById, getColaboradores } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    marginTop: '5%',
  },
  grid: {},
}));

export default function Contratos() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = React.useContext(StoreContext);
  const [contrato, setContrato] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalEditOpen, setmodalEditOpen] = React.useState(false);
  const [usersLoja, setUsersLojas] = React.useState([]);

  function handleGONewProject() {
    navigate(`/app/cliente/contrato/${id}/newproject`);
  }
  async function getCurrentContract() {
    try {
      const response = await getContractById(id, token);
      setContrato(response);
      console.log('contrato', response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('erro ao obter contrato', e);
    }
  }
  async function getCurrentColoboradores() {
    try {
      const colaboradores = await getColaboradores(token);
      console.log('colaboradores', colaboradores);
      setUsersLojas(colaboradores);
    } catch (e) {
      console.log('erro ao obter colaboradores', e);
    }
  }

  async function refreshFunction() {
    await getCurrentColoboradores();
    await getCurrentContract();
  }
  React.useEffect(() => {
    (async () => {
      await getCurrentColoboradores();
      await getCurrentContract();
    })();
  }, []);
  return (
    <Page className={classes.root} title="Contratos">
      <Container maxWidth={false}>
        {loading ? (
          <LinearProgress color="secondary" />
        ) : (
          <>

            <Grid container spacing={1}>
              <Grid
                style={{ marginBottom: 10 }}
                className={classes.grid}
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <ToolbarContract
                  handleOpenModal={handleGONewProject}
                  contrato={contrato}
                  handleEditModal={setmodalEditOpen}
                />
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: 10, marginBottom: 10 }}
              className={classes.grid}
              item
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <TableContrato contrato={contrato} />
            </Grid>
            <Grid container spacing={1}>
              <Grid
                className={classes.grid}
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <AccordionStatus
                  title="Checklist Entrada"
                  status={contrato.statusentrada}
                />
              </Grid>
              <Grid
                className={classes.grid}
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <AccordionStatus
                  title="Checklist Entrega"
                  status={contrato.statusentrega}
                />
              </Grid>
              <Grid
                className={classes.grid}
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <AccordionStatusRivisao
                  title="Checklist Revisão"
                  status={contrato.statusrevisao}
                />
              </Grid>
            </Grid>

            <ModalProjeto
              users={usersLoja}
              open={modalOpen}
              contrato_id={id}
              handleClickOpen={setModalOpen}
              handleClose={setModalOpen}
              refreshFunction={refreshFunction}
            />
            <ModalEditContrato
              users={usersLoja}
              open={modalEditOpen}
              contrato_id={id}
              handleClickOpen={setmodalEditOpen}
              handleClose={setmodalEditOpen}
              refreshFunction={refreshFunction}
            />
          </>
        )}
      </Container>
    </Page>
  );
}
