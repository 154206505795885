/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useParams } from 'react-router-dom';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { getColaboradores, addNewProject } from '../../../services';
import Page from '../../../components/Page';
import StoreContext from '../../../store/context';
// import TheUploader from '../../../components/TheUploader';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  grid: {
    backgroundColor: '#FFFFF',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
}));

// function getSteps() {
//   return ['Nome do projeto e colaborador', 'Selecione os PDFs', 'Create an ad'];
// }

export default function NewProject() {
  const classes = useStyles();
  const { token } = React.useContext(StoreContext);
  // const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState({});
  const [users, setUsers] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState({
    descricao: '',
    observacao: '',
    user_ower: 0,
    contrato_id: id,
    montador: '',
  });
  // const [pdf, setPdf] = useState(null);

  // function onFileDraged(file) {
  //   console.log('file', file);
  //   setPdf(file[0]);
  // }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  async function handleSaveProject() {
    const { contrato_id, descricao, observacao, user_ower } = values;
    try {
      setIsReady(false);
      await addNewProject(token, {
        contrato_id,
        descricao,
        observacao,
        user_ower,
      });
      navigate(`/app/cliente/contrato/${contrato_id}`);
    } catch (error) {
      setIsReady(true);
    }
  }
  // const steps = getSteps();

  // const totalSteps = () => steps.length;

  // const completedSteps = () => Object.keys(completed).length;

  // const isLastStep = () => activeStep === totalSteps() - 1;

  // const allStepsCompleted = () => completedSteps() === totalSteps();

  // const handleNext = () => {
  //   const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
  //   setActiveStep(newActiveStep);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  //   console.log('pdf', pdf);
  // };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };
  // function getStepContent(step) {
  //   switch (step) {
  //     case 0:
  //       return (<><Step1 /></>);
  //     case 1:
  //       return (<><Step2 /></>);
  //     case 2:
  //       return 'Step 3: This is the bit I really care about!';
  //     default:
  //       return 'Unknown step';
  //   }
  // }

  // function Step1() {
  //   return (
  //     <Paper elevation={1}>
  //       <Grid
  //         item
  //         sm={12}
  //         md={6}
  //         lg={6}
  //         xl={6}
  //         style={{
  //           marginLeft: 'auto',
  //           marginRight: 'auto',
  //           paddingTop: 20,
  //           paddingBottom: 20,
  //           minHeight: 400,
  //           flex: 1,
  //         }}
  //       >
  //         <TextField
  //           style={{ marginTop: 10 }}
  //           autoFocus
  //           margin="dense"
  //           id="descricao"
  //           label="Descrição"
  //           type="text"
  //           name="descricao"
  //           fullWidth
  //           onChange={handleChange}
  //           value={values.descricao}
  //         />
  //         <TextField
  //           style={{ marginTop: 10 }}
  //           autoFocus
  //           margin="dense"
  //           id="observacao"
  //           label="Observação"
  //           type="text"
  //           name="observacao"
  //           fullWidth
  //           onChange={handleChange}
  //           value={values.observacao}
  //         />

  //         <FormControl
  //           style={{ marginTop: 10, maxWidth: '50wh' }}
  //           className={classes.formControl}
  //         >
  //           <InputLabel id="demo-simple-select-label">Montador</InputLabel>
  //           <Select
  //             labelId="demo-simple-select-label"
  //             id="montador"
  //             onChange={handleChange}
  //             value={values.user_ower}
  //             fullWidth
  //             name="user_ower"
  //           >
  //             {users.map((item) => (
  //               <MenuItem key={item.id} value={item.id}>
  //                 {item.name}
  //               </MenuItem>
  //             ))}
  //           </Select>
  //         </FormControl>
  //       </Grid>
  //     </Paper>

  //   );
  // }

  // function Step2() {
  //   return (
  //     <Paper elevation={1}>
  //       <Grid
  //         item
  //         sm={12}
  //         md={6}
  //         lg={6}
  //         xl={6}
  //         style={{
  //           marginLeft: 'auto',
  //           marginRight: 'auto',
  //           paddingTop: 20,
  //           paddingBottom: 20,
  //           minHeight: 400,
  //           flex: 1,
  //         }}
  //       >
  //         <TheUploader onFileDraged={onFileDraged} />
  //       </Grid>
  //     </Paper>
  //   );
  // }

  useEffect(() => {
    (async () => {
      const u = await getColaboradores(token);
      setUsers(u);
    })();
  }, []);

  useEffect(() => {
    const { descricao, user_ower } = values;
    if (descricao.length > 0 && user_ower > 0) {
      setIsReady(false);
    } else {
      setIsReady(true);
    }
  }, [values]);
  return (
    <Page title="Novo Projeto">
      <Grid
        item
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingTop: 20,
          paddingBottom: 20,
          minHeight: '100vh',
          flex: 1,
        }}
      >
        <Paper style={{ padding: 20 }} elevation={1}>
          <Typography align="center" variant="h3">
            Novo Projeto
          </Typography>
          <TextField
            style={{ marginTop: 10 }}
            autoFocus
            margin="dense"
            id="descricao"
            label="Descrição"
            type="text"
            name="descricao"
            fullWidth
            onChange={handleChange}
            value={values.descricao}
          />
          <TextField
            style={{ marginTop: 10 }}
            autoFocus
            margin="dense"
            id="observacao"
            label="Observação"
            type="text"
            name="observacao"
            fullWidth
            onChange={handleChange}
            value={values.observacao}
          />

          <FormControl
            style={{ marginTop: 10, maxWidth: '50wh' }}
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-label">Montador</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="montador"
              onChange={handleChange}
              value={values.user_ower}
              fullWidth
              name="user_ower"
            >
              {users.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            disabled={isReady}
            color="primary"
            fullWidth
            style={{ marginTop: 20, marginBottom: 10 }}
            variant="contained"
            onClick={() => handleSaveProject()}
          >
            Cadastrar
          </Button>
        </Paper>
      </Grid>
    </Page>
  );
}
