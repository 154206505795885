import React, { useContext, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Snackbar from '../../../components/Snackbar';
import StoreContext from '../../../store/context';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function SignIn() {
  const { setToken, setUser } = useContext(StoreContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();
  // const history = useHistory();
  // const [show, setShow] = useState(false);
  // // const handleClick = () => setShow(!show);
  function showSnackbar(currentMessage, currentYype) {
    setMessage(currentMessage);
    setType(currentYype);
    setOpen(true);
  }
  async function handleLogin(values, { setSubmitting }) {
    try {
      const { data } = await api.post('session', values);
      setUser({
        id: data.id, name: data.name, email: data.email, cargo: data.cargo, loja_id: data.loja_id,
      });
      setToken(data.token);
      showSnackbar('Login efetuado com sucesso', 'success');
      setSubmitting(false);
      setTimeout(() => {
        navigate('/app', { replace: true });
      }, 1000);
    } catch (e) {
      showSnackbar('erro ao efetuar login', 'error');
      setSubmitting(false);
    }
  }
  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
              client: 'web',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Precisa ser um e-mail válido').max(255).required('Email é obrigatório'),
              password: Yup.string().max(255).required('Senha é obrigatória'),
            })}
            onSubmit={handleLogin}
            handleChange={() => console.log('change')}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>

                <Grid
                  container
                  spacing={3}
                />
                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Monta Ai Dashboard
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Entrar
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {/* Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link> */}
                </Typography>
              </form>
            )}
          </Formik>

        </Container>
      </Box>
      <Snackbar message={message} open={open} setOpen={setOpen} type={type} />
    </div>
  );
}
