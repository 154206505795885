/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button, TextField,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import StoreContext from '../../store/context';
import { setHeaders } from '../../utils/headers';

const useStyles = makeStyles((theme) => createStyles({
  formControl: {

    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModalUserResetpassword({
  open, handleClose, refreshFunction, colaborador,
}) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [values, setValues] = useState({
    password: '',
  });

  useEffect(() => {
    setValues({
      password: '',
    });
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  async function handleReset() {
    try {
      const data = await api.put(`users/${colaborador.id}`, values, setHeaders(token));
      handleClose(false);
      refreshFunction();
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>

      <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Alterar Senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Alterar senha do colaborador
            <br />
            {colaborador.name}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Nova Senha"
            type="password"
            name="password"
            fullWidth
            onChange={handleChange}
            value={values.password}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancelar
          </Button>

          <Button onClick={handleReset} color="primary">
            Alterar
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}
