import React from 'react';

import LojasTable from '../../components/LojasTable';

export default function Lojas() {
  return (
    <div>
      <LojasTable />
    </div>
  );
}
