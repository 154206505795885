/* eslint-disable react/prop-types */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import {
  makeStyles, Paper, Typography, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,

  },
  primaryText: {
    fontSize: 10,
  },
  cursor: {
    cursor: 'pointer',
  },
}));

function Basic({ onFileDraged }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxFiles: 5, accept: 'application/pdf', onDrop: (file) => { onFileDraged(file); } });
  const classes = useStyles();
  const files = acceptedFiles.map((file) => (
    <ListItem style={{ maxWidth: '100%' }} button key={file.path}>
      <ListItemIcon>
        <PictureAsPdfIcon />
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.primaryText }} inset primary={file.name} />

    </ListItem>
  ));

  return (
    <div style={{
      maxWidth: '100%', display: 'flex', flexDirection: 'column',
    }}
    >
      <Paper style={{ minHeight: 60, marginTop: 10, width: '100%' }} elevation={1} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography align="center" variant="h6" className={classes.cursor} component="h2">Arraste o arquivo aqui ou clique.</Typography>
      </Paper>
      <Paper style={{ minHeight: 60, marginTop: 10, width: '100%' }} elevation={1}>
        <List component="nav" className={classes.root}>{files}</List>
      </Paper>
    </div>
  );
}

export default Basic;
