/* eslint-disable react/prop-types */
import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, IconButton } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import moment from 'moment';
import WidgetsIcon from '@material-ui/icons/Widgets';
import CreateIcon from '@material-ui/icons/Create';
// const useStyles = makeStyles(() => ({

//   paper: {
//     width: '100wh',
//     height: 80,
//     padding: 20,
//   },
// }));

export default function ToolbarContract({
  contrato,
  handleOpenModal,
  handleEditModal,
}) {
  return (
    <>
      <Typography variant="h3" component="span" display="inline">
        {contrato.descricao}
        {' '}
        &nbsp; &nbsp;
        <IconButton onClick={() => handleEditModal(true)} color="primary">
          <CreateIcon />
        </IconButton>
      </Typography>
      <Typography
        style={{ display: 'flex', alignItems: 'center' }}
        display="inline"
        variant="body2"
        component="span"
      >
        <WidgetsIcon />
        {' '}
        &nbsp;
        {contrato.numero_contrato
          ? contrato.numero_contrato
          : 'Sem numero de contrato'}
        &nbsp; &nbsp; &nbsp; &nbsp;
        <EventAvailableIcon />
        {' '}
      &nbsp;
        {moment(contrato.createdAt).format('DD/MM/YYYY')}
        &nbsp; &nbsp; &nbsp; &nbsp;
        <Button
          onClick={() => handleOpenModal()}
          style={{ fontSize: 12, textTransform: 'capitalize' }}
          color="primary"
          variant="contained"
        >
          novo projeto
        </Button>
      </Typography>
    </>
  );
}
