/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { deleteColaborador } from '../../services/index';
import StoreContext from '../../store/context';

const useStyles = makeStyles((theme) => createStyles({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModalDeleteColaborador({
  open,
  handleClose,
  colaborador,
  refreshFunction,
}) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);

  async function handleDeleteUser() {
    try {
      console.log(colaborador.id);
      await deleteColaborador(colaborador.id, token);
      handleClose(false);
      refreshFunction();
    } catch (e) {
      console.log('erro ao deletar user', e);
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >

        <DialogTitle id="form-dialog-title">Delete Usuários</DialogTitle>

        <DialogContent>
          <Typography variant="h6" component="span">Tem certeza que deseja deletar o Usuário ?</Typography>
          <br />
          <br />
          <p id="server-modal-description">{colaborador.name}</p>
          <p id="server-modal-description">{colaborador.email}</p>
        </DialogContent>
        <br />
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="contained" color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteUser} variant="contained" color="primary">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
