import api from './api';
import { setHeaders } from '../utils/headers';

export const getColaboradores = async (token) => {
  try {
    const { data } = await api.get('colaborador', setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const getContractById = async (id, token) => {
  try {
    const { data } = await api.get(`contrato/${id}`, setHeaders(token));
    await console.log('teste', data);
    return data;
  } catch (e) {
    return e;
  }
};

export const addNewContract = async (token, body) => {
  try {
    const { data } = await api.post('contrato', body, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const addNewProject = async (token, body) => {
  try {
    const { data } = await api.post('projeto', body, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const getProject = async (token, id) => {
  try {
    const { data } = await api.get(`projeto/${id}`, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const EditContrato = async (token, id, descricao) => {
  try {
    const body = { descricao };
    const { data } = await api.put(`contrato/${id}`, body, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const EditProjeto = async (token, id, body) => {
  try {
    const { data } = await api.put(`projeto/${id}`, body, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const DeleteProjeto = async (token, id) => {
  try {
    const projeto = await api.delete(`projeto/${id}`, setHeaders(token));
    return projeto;
  } catch (e) {
    return e;
  }
};

export const getMeusColaboradores = async (token) => {
  try {
    const { data } = await api.get('colaborador', setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};
export const deleteColaborador = async (id, token) => {
  try {
    const { data } = await api.delete(`colaborador/${id}`, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const EditColaborador = async (id, token, body) => {
  try {
    console.log(token);
    const { data } = await api.put(
      `colaborador/${id}`,
      body,
      setHeaders(token),
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getColaborador = async (id, token) => {
  try {
    const { data } = await api.get(`colaborador/${id}`, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};

export const uploadPDF = async (id, token, formaData) => {
  try {
    const { data } = await api.post(
      `uploadpdf/${id}/projeto`,
      formaData,
      setHeaders(token),
    );
    return data;
  } catch (e) {
    return e;
  }
};
export const deletePdf = async (id, IdAsset, token) => {
  try {
    const { data } = await api.post('deletePdf', { projectId: id, idAsset: IdAsset }, setHeaders(token));
    return data;
  } catch (e) {
    return e;
  }
};
