/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card,
  CardContent, Typography, IconButton,

} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ProjetoStatusTable({ projeto }) {
  const classes = useStyles();

  return (
    <Card>

      <CardContent>
        <TableContainer component={Paper}>
          <Typography align="center" style={{ marginTop: 30, marginBottom: 30 }} variant="h5" component="h5">
            {projeto.clientes.nome}
            {' '}
            -
            {' '}
            {projeto.ambiente}
          </Typography>
          <Typography align="center" style={{ marginTop: 30, marginBottom: 30 }} variant="h6" component="h6">
            Baixar PDF
            <IconButton onClick={() => window.open(
              projeto.files[0].url,
              '_blank',
            )}
            >
              <PictureAsPdfIcon />

            </IconButton>
          </Typography>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Entrega</TableCell>
                <TableCell align="center">Entrada</TableCell>
                <TableCell align="center">Montagem</TableCell>
                <TableCell align="center">Vistoria</TableCell>
                <TableCell align="center">assinatura</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              <TableRow>
                <TableCell component="th" align="center">
                  {projeto.entrega ? <CheckCircleTwoToneIcon style={{ color: 'green' }} /> : <BlockTwoToneIcon style={{ color: 'red' }} /> }

                </TableCell>
                <TableCell align="center">{projeto.entrada ? <CheckCircleTwoToneIcon style={{ color: 'green' }} /> : <BlockTwoToneIcon style={{ color: 'red' }} />}</TableCell>
                <TableCell align="center">{projeto.montagem ? <CheckCircleTwoToneIcon style={{ color: 'green' }} /> : <BlockTwoToneIcon style={{ color: 'red' }} />}</TableCell>

                <TableCell align="center">{projeto.vistoria ? <CheckCircleTwoToneIcon style={{ color: 'green' }} /> : <BlockTwoToneIcon style={{ color: 'red' }} />}</TableCell>
                <TableCell align="center">{projeto.assinatura ? <CheckCircleTwoToneIcon style={{ color: 'green' }} /> : <BlockTwoToneIcon style={{ color: 'red' }} />}</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
