/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react';

import {
  Box,
  Container,
  makeStyles,
  Card,
  CardHeader,
  Button,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Page from '../../../components/Page';
import FormCliente from '../../../components/FormCliente';
import api from '../../../services/api';
import { setHeaders } from '../../../utils/headers';
import StoreContext from '../../../store/context';
import ListContrato from '../../../components/ListContrato';
import ModalContrato from '../../../components/ModalContrato';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function DetalheCliente() {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const { id } = useParams();
  const [cliente, setCliente] = useState({});
  const [loading, setLoading] = useState(true);
  const [usersLoja, setUsersLojas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    async function getLoja() {
      const l = await api.get(`cliente/${id}`, setHeaders(token));
      const u = await api.get('colaborador', setHeaders(token));
      console.log('meus usuários', u.data);

      setUsersLojas(u.data);
      setCliente(l.data);
      setLoading(false);
    }
    getLoja();
  }, []);

  async function getRefreshLoja() {
    setLoading(true);
    const l = await api.get(`cliente/${id}`, setHeaders(token));
    const u = await api.get('colaborador', setHeaders(token));

    setCliente(l.data);
    setUsersLojas(u.data);
    setLoading(false);
  }
  return (
    <Page
      className={classes.root}
      title={`Monta ai - ${cliente.nome}`}
    >
      <Container maxWidth={false}>
        {loading ? (
          <>
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
          </>
        )
          : (
            <>
              <FormCliente refreshFunction={getRefreshLoja} title={cliente.nome} editMode subheader="Aqui estão as informações deste cliente" cliente={cliente} />
              <Box mt={3} />
              <Card>
                <CardHeader
                  style={{ margin: 10 }}
                  subheader=""
                  title="Contratos"
                  action={(
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setModalOpen(true)}
                    >
                      Novo Contrato
                    </Button>
)}
                />
                <ListContrato contratos={cliente.contratos} />
              </Card>
              {!loading
                ? <ModalContrato users={usersLoja} refreshFunction={getRefreshLoja} open={modalOpen} clienteID={id} handleClickOpen={setModalOpen} handleClose={setModalOpen} /> : null}
            </>
          )}
      </Container>
    </Page>
  );
}
