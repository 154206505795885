import React from 'react';
import { Navigate } from 'react-router-dom';
// import StoreContext from '../store/context';
// import StoreProvider from '../store/Provider';
// import RoutesPrivate from '../pages/Private';

// import Main from '../pages/Main';

import DashboardLayout from '../layouts/DashboardLayout';
import Lojas from '../pages/Lojas';
import NovaLoja from '../pages/Lojas/Nova';
import LojaDetalhe from '../pages/Lojas/Detalhe';
import Usuarios from '../pages/Usuarios';
import EditUsuario from '../pages/Usuarios/EditUsuario';
import MainLayout from '../layouts/MainLayout';
import SignIn from '../pages/Auth/SignIn';

import Clientes from '../pages/Clientes';
import NovoCliente from '../pages/Clientes/NovoCliente';
import ClienteDetalhe from '../pages/Clientes/Detalhe';
import Projetos from '../pages/Projetos';
import EditProject from '../pages/Projetos/EditProject';
import NewProject from '../pages/Projetos/NewProject';
import Contratos from '../pages/Contratos';
// const Routes = () => (
//   <BrowserRouter>
//     <StoreProvider>
//       <Switch>
//         <Route path="/login" component={SignIn} />
//         <RoutesPrivate path="/" exact component={DashboardLayout}>
//           {/* <Route exact children path="app/lojas" component={Lojas} /> */}
//         </RoutesPrivate>

//       </Switch>
//     </StoreProvider>
//   </BrowserRouter>
// );

const routes = (isLoggedIn, user) => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children:
      user?.cargo === 'master'
        ? [
            { path: '/', element: <Lojas /> },
            { path: '/novaloja', element: <NovaLoja /> },
            { path: '/loja/:id', element: <LojaDetalhe /> },
            { path: 'usuarios', element: <Usuarios /> },
            //   { path: 'dashboard', element: <DashboardView /> },
            //   { path: 'products', element: <ProductListView /> },s
            //   { path: 'settings', element: <SettingsView /> },
            //   { path: '*', element: <Navigate to="/404" /> }
          ]
        : [
            { path: '/', element: <Clientes /> },
            { path: '/novocliente', element: <NovoCliente /> },
            { path: '/cliente/:id', element: <ClienteDetalhe /> },
            { path: '/cliente/contrato/:id', element: <Contratos /> },
            {
              path: '/cliente/contrato/:id/project/:id',
              element: <EditProject />,
            },
            {
              path: '/cliente/contrato/:id/newproject',
              element: <NewProject />,
            },
            { path: '/cliente/projeto/:id', element: <Projetos /> },
            { path: 'usuarios', element: <Usuarios /> },
            { path: '/usuarios/:id', element: <EditUsuario /> },
            //   { path: 'dashboard', element: <DashboardView /> },
            //   { path: 'products', element: <ProductListView /> },s
            //   { path: 'settings', element: <SettingsView /> },
            //   { path: '*', element: <Navigate to="/404" /> }
          ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <SignIn /> },
      // { path: 'register', element: <RegisterView /> },
      {
        path: '404',
        element: (
          <>
            <h1>Não encontrado</h1>
          </>
        ),
      },
      { path: '/', element: <Navigate to="/app" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
