/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import api from '../../services/api';

import StoreContext from '../../store/context';

import { setHeaders } from '../../utils/headers';
import { addNewContract } from '../../services';

const useStyles = makeStyles((theme) => createStyles({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModalContrato({
  users,
  handleClickOpen,
  open,
  handleClose,
  editMode,
  clienteID,
  refreshFunction,
  id,
}) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [values, setValues] = useState({
    descricao: '',
    numero_contrato: '',
    cliente_id: 0,
    id: 0,
  });

  const [disabled, setDisabled] = useState(false);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (editMode) {
      setValues({ ...values, id, cliente_id: parseInt(clienteID) });
    } else {
      setValues({ ...values, cliente_id: parseInt(clienteID) });
    }
  }, []);

  async function handleAddContrato() {
    try {
      setDisabled(true);
      const {
        descricao, numero_contrato, cliente_id,
      } = values;
      await addNewContract(token, {
        descricao, numero_contrato, cliente_id,
      });
      await refreshFunction();
      handleClose(false);
      console.log(values);
    } catch (e) {
      setDisabled(false);
      console.log(e);
    }
  }

  return (
    <div>
      <Dialog
        style={{ maxWidth: '50wh' }}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >
        {editMode ? (
          <DialogTitle id="form-dialog-title">Editar projeto</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">Nova Contrato</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>Digite as Informações</DialogContentText>
          <TextField
            style={{ marginTop: 10 }}
            autoFocus
            margin="dense"
            id="descricao"
            label="Descrição"
            type="text"
            name="descricao"
            fullWidth
            onChange={handleChange}
            value={values.descricao}
          />
          <TextField
            style={{ marginTop: 10 }}
            autoFocus
            margin="dense"
            id="numero_contrato"
            label="Numero do contrato"
            type="text"
            name="numero_contrato"
            fullWidth
            onChange={handleChange}
            value={values.numero_contrato}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            onClick={() => handleClose(false)}
            color="primary"
          >
            Cancelar
          </Button>
          {editMode ? (
            <Button disabled={disabled} onClick={handleClose} color="primary">
              Alterar
            </Button>
          ) : (
            <Button
              disabled={disabled}
              onClick={() => handleAddContrato()}
              color="primary"
            >
              Cadastrar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
