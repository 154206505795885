/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import StoreContext from '../../store/context';
import { setHeaders } from '../../utils/headers';

const useStyles = makeStyles((theme) => {
  createStyles({
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  });
});

export default function FormDialog({
  handleClickOpen,
  open,
  handleClose,
  editMode,
  lojaId,
  currentUser,
  refreshFunction,
}) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    cargo: 'admin',
    loja_id: lojaId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (editMode) {
      setValues(currentUser);
    } else {
      setValues({ ...values, loja_id: lojaId });
    }
  }, []);

  async function handleAddUser() {
    try {
      setIsLoading(true);
      const data = await api.post(
        'colaborador',
        { ...values, loja_id: lojaId },
        setHeaders(token),
      );
      setIsLoading(false);

      refreshFunction();
    } catch (e) {
      setError(true);
      setIsLoading(false);

      console.log(e);
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >
        {editMode ? (
          <DialogTitle id="form-dialog-title">Editar usuário</DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">Nova usuário</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>Digite as Informações</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            name="email"
            fullWidth
            onChange={handleChange}
            value={values.email}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            name="name"
            type="text"
            fullWidth
            onChange={handleChange}
            value={values.name}
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Cargo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleChange}
              value={values.cargo}
              fullWidth
              name="cargo"
            >
              <MenuItem value="admin">Administrador</MenuItem>
              <MenuItem value="supervisor">Supervisor</MenuItem>
              <MenuItem value="montador">Montador</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Senha"
            type="password"
            name="password"
            fullWidth
            onChange={handleChange}
            value={values.password}
          />

          {error && <Alert severity="error">E-mail ja existe</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancelar
          </Button>
          {editMode ? (
            <Button disabled={isLoading} onClick={handleClose} color="primary">
              Alterar
            </Button>
          ) : (
            <Button
              disabled={isLoading}
              onClick={() => handleAddUser()}
              color="primary"
            >
              Cadastrar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
