/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card,
  CardContent, IconButton,

} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';

// import { useNavigate } from 'react-router-dom';

import ModalUserResetpassword from '../ModalUserResetpassword';
import ModalDeleteColaborador from '../ModalDeleteColaborador';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ListUser({ users, refreshFunction }) {
  const classes = useStyles();
  // const navigate = useNavigate();
  const [modalReset, setmodalReset] = useState(false);
  const [modalDeleteOpen, setModaDeleteOpen] = useState(false);

  return (
    <Card>

      <CardContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Nome</TableCell>
                <TableCell align="center">E-mail</TableCell>
                <TableCell align="center">Cargo</TableCell>
                <TableCell align="center">Opções</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.cargo}</TableCell>
                  <TableCell align="center">
                    <div>
                      <IconButton
                        onClick={() => setmodalReset(true)}
                        aria-label="resetSenha"
                      >
                        <LockIcon />
                      </IconButton>
                      {/* <IconButton
                        onClick={() => navigate(`/app/usuarios/${user.id}`)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton> */}
                      <IconButton
                        onClick={() => setModaDeleteOpen(true)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <ModalDeleteColaborador
                      open={modalDeleteOpen}
                      handleClose={setModaDeleteOpen}
                      colaborador={user}
                      refreshFunction={refreshFunction}
                    />
                    <ModalUserResetpassword
                      open={modalReset}
                      handleClose={setmodalReset}
                      colaborador={user}
                      refreshFunction={refreshFunction}
                    />
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
