import React, { useState, useEffect, useContext } from 'react';

// import { Container } from './styles';
import {
  Paper,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { getColaborador, EditColaborador } from '../../../services/index';
import StoreContext from '../../../store/context';

function EditUsuario() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, user } = useContext(StoreContext);
  const [values, setValues] = useState({
    name: '',
    email: '',
    cargo: '',
    loja_id: user.loja_id,
  });
  const [loading, setLoading] = useState(true);

  async function getCurrentColaborador() {
    try {
      const data = await getColaborador(id, token);
      setValues({
        ...values,
        name: data.name,
        email: data.email,
        cargo: data.cargo,
      });
      setLoading(false);
      console.log('current user', data);
    } catch (e) {
      console.log(e);
    }
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  async function handleEditUser() {
    await EditColaborador(id, token, values);
    navigate(-1);
  }
  useEffect(() => {
    (async () => {
      console.log(user);
      await getCurrentColaborador();
    })();
  }, []);
  return (
    <Box padding={5}>
      {loading ? (
        <>
          <h3>Carregando</h3>
        </>
      ) : (
        <Paper elevation={2} maxWidth={2}>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            name="email"
            fullWidth
            onChange={handleChange}
            value={values.email}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            name="name"
            type="text"
            fullWidth
            onChange={handleChange}
            value={values.name}
          />

          <InputLabel id="demo-simple-select-label">Cargo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange}
            value={values.cargo}
            fullWidth
            name="cargo"
          >
            <MenuItem value="admin">Administrador</MenuItem>
            <MenuItem value="supervisor">Supervisor</MenuItem>
            <MenuItem value="montador">Montador</MenuItem>
          </Select>

          <Button onClick={() => handleEditUser()} color="primary">
            Atualizar
          </Button>
        </Paper>
      )}
    </Box>
  );
}

export default EditUsuario;
