/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card,
  CardContent, Button,

} from '@material-ui/core';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ListContrato({ contratos }) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card>

      <CardContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Descrição</TableCell>
                <TableCell align="center">Código do cliente</TableCell>
                <TableCell align="center">Data de criação</TableCell>
                <TableCell align="center">Data da última atualização</TableCell>
                <TableCell align="center">Opções</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contratos.map((contrato) => (
                <TableRow key={contrato.id}>
                  <TableCell align="center" scope="row">
                    {contrato.descricao}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {contrato.numero_contrato}
                  </TableCell>
                  <TableCell align="center">{moment(contrato.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="center">{moment(contrato.updated_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="center" color="primary">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => navigate(`/app/cliente/contrato/${contrato.id}`, { replace: true })}
                    >
                      Ver Contrato
                    </Button>

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
