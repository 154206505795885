import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Container,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { useNavigate } from 'react-router-dom';
import ModalDeleteColaborador from '../../components/ModalDeleteColaborador';
import StoreContext from '../../store/context';
import { getMeusColaboradores } from '../../services';
import ModalUser from '../../components/ModalUser';
import ModalUserResetpassword from '../../components/ModalUserResetpassword';

export default function Usuarios() {
  const navigate = useNavigate();
  const { token, user } = useContext(StoreContext);
  const [colaboradores, setColaboradores] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalReset, setmodalReset] = useState(false);
  const [modalDeleteOpen, setModaDeleteOpen] = useState(false);
  async function getUsers() {
    try {
      const colaboradoresData = await getMeusColaboradores(token);
      console.log('COlaboradores', colaboradoresData);
      setColaboradores(colaboradoresData);
    } catch (e) {
      console.log('erro ao obter usuários');
    }
  }
  useEffect(() => {
    (async () => {
      await getUsers();
      console.log('user', user);
    })();
  }, []);

  return (
    <Container maxWidth={false}>
      <Box display="flex" justifyContent="flex-end" marginTop="2rem">
        {/* <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => setModalIsOpen(true)}
        >
          Novo Usuário
        </Button>
      </Box>
      <Box marginTop="2rem">
        <Paper>
          <Card>
            <CardContent>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">E-mail</TableCell>
                      <TableCell align="center">Cargo</TableCell>
                      <TableCell align="center">Opções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {colaboradores.map((colaborador) => (
                      <TableRow key={colaborador.id}>
                        <TableCell component="th" scope="row">
                          {colaborador.name}
                        </TableCell>
                        <TableCell align="center">
                          {colaborador.email}
                        </TableCell>
                        <TableCell align="center">
                          {colaborador.cargo}
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <IconButton
                              onClick={() => setmodalReset(true)}
                              aria-label="resetSenha"
                            >
                              <LockIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => navigate(`/app/usuarios/${colaborador.id}`)}
                              aria-label="edit"
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setModaDeleteOpen(true)}
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                        <ModalDeleteColaborador
                          open={modalDeleteOpen}
                          handleClose={setModaDeleteOpen}
                          colaborador={colaborador}
                          refreshFunction={getUsers}
                        />
                        <ModalUserResetpassword
                          open={modalReset}
                          handleClose={setmodalReset}
                          colaborador={colaborador}
                          refreshFunction={getUsers}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Paper>
      </Box>
      <ModalUser
        open={modalIsOpen}
        handleClose={setModalIsOpen}
        lojaId={user.loja_id}
        refreshFunction={getUsers}
      />
    </Container>
  );
}
