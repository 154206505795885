/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react';

import {
  Box,
  Container,
  makeStyles,
  Card,
  CardHeader,
  Button,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Page from '../../../components/Page';
import FormLoja from '../../../components/FormLoja';
import api from '../../../services/api';
import { setHeaders } from '../../../utils/headers';
import StoreContext from '../../../store/context';
import ListUser from '../../../components/ListUser';
import ModalUser from '../../../components/ModalUser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function NovaLoja() {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const { id } = useParams();
  const [loja, setLoja] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    async function getLoja() {
      const l = await api.get(`loja/${id}`, setHeaders(token));
      setLoja(l.data);
      setLoading(false);
    }
    setTimeout(() => { getLoja(); }, 2000);
  }, []);

  async function getRefreshLoja() {
    setLoading(true);
    const l = await api.get(`loja/${id}`, setHeaders(token));
    setTimeout(() => {
      setLoja(l.data);
      setLoading(false);
    }, 2000);
  }
  return (
    <Page
      className={classes.root}
      title={`Monta ai - ${loja.franquia}`}
    >
      <Container maxWidth={false}>
        {loading ? (
          <>
            <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
            <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
          </>
        )
          : (
            <>
              <FormLoja refreshFunction={getRefreshLoja} title={loja.franquia} editMode subheader="Aqui estão as informações da loja" loja={loja} />
              <Box mt={3} />
              <Card>
                <CardHeader
                  style={{ margin: 10 }}
                  subheader=""
                  title="Usuários"
                  action={(
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setModalOpen(true)}
                    >
                      Novo Usuário
                    </Button>
)}
                />
                <ListUser users={loja.users} refreshFunction={getRefreshLoja} />
              </Card>
              <ModalUser refreshFunction={getRefreshLoja} open={modalOpen} lojaId={id} handleClickOpen={setModalOpen} handleClose={setModalOpen} />
            </>
          )}
      </Container>
    </Page>
  );
}
