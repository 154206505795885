import React, { useContext } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import GlobalStyles from './components/GlobalStyles';
import routes from './routes';
import theme from './theme';
import StoreContext from './store/context';

function App() {
  const { token, user } = useContext(StoreContext);
  const routing = useRoutes(routes(token, user));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
}

export default App;
