import React from 'react';

import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';

import Page from '../../../components/Page';
import FormLoja from '../../../components/FormLoja';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const loja = {
  franquia: 'Montai unidade 1',
  nome_fantasia: 'Monta ai',
  razao_social: 'Monta ai servicos',
  cnpj: '12345678901234',
  inscricao_estadual: 'isento',
  email: 'ze@email',
  cep: '00000000',
  logadouro: 'Tocantins',
  numero: '1210',
  complemento: ' ',
  bairro: 'Ipiranga',
  cidade: 'Ribeirão Preto',
  estado: 'SP',
  telefone: '16988020195',
  proprietario: 'Jose Guilherme Campos',
  created_at: '2020-12-22 09:49:25',
  updated_at: '2020-12-22 09:49:25',
  id: 9,
};
export default function NovaLoja() {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title="Add Loja"
    >
      <Container maxWidth={false}>
        <FormLoja loja={loja} />
        <Box mt={3} />
      </Container>
    </Page>
  );
}
