import React, { useState, useEffect, useContext } from 'react';

import {
  Paper,
  Container,
  makeStyles,

} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from 'react-router-dom';
import StoreContext from '../../store/context';
import ProjetoStatusTable from '../../components/ProjetoStatusTable';
import api from '../../services/api';
import { setHeaders } from '../../utils/headers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

export default function Projetos() {
  const [projeto, setProjeto] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  useEffect(() => {
    async function getProjeto() {
      const l = await api.get(`projeto/${id}`, setHeaders(token));
      setProjeto(l.data);
      console.log('data', l.data);
      setLoading(false);
    }
    getProjeto();
  }, []);
  return (
    <Paper className={classes.root} elevation={3}>

      {loading ? (
        <>
          <Skeleton style={{ margin: 20 }} variant="rect" height={100} />
          <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
          <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
          <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
          <Skeleton style={{ margin: 20 }} variant="rect" height={70} />
        </>
      )

        : (
          <Container>

            <Container xs={12} sm={12} lg={12} md={12} item>
              <ProjetoStatusTable projeto={projeto} />
            </Container>
          </Container>
        )}
    </Paper>
  );
}
